/* eslint-disable import/prefer-default-export */
import axios from '../Axios'

const module = '/discounts'

export const getDiscountsByPartner = async (
  filtersDiscounts,
  page = 0,
  limit = 6
) => {
  const { id, order } = filtersDiscounts
  const { data } = await axios.get(
    `${module}/?id=${id}&page=${page}&limit=${limit}&order=${order}`
  )
  return data
}

export const createNewDiscount = async (formData) => {
  const { data } = await axios.post(`${module}/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const updateDiscount = async ({ userId, formData }) => {
  const { data } = await axios.patch(`${module}/${userId}`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })
  return data
}

export const deleteDiscount = async (userId) => {
  const { data } = await axios.delete(`${module}/${userId}`)
  return data
}
