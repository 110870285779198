import React, { useEffect, useState } from 'react'
import { useFormik } from 'formik'
import { Flex, Grid, GridItem, Tooltip, Text } from '@chakra-ui/react'
import {
  BarFileName,
  Button,
  InputComtrol,
  Select,
  SquareButton,
  UploadFile
} from '../../../UI'
import {
  AccountType,
  BanksList,
  Files,
  FormatRut,
  RelationshipList3
} from '../../../../Utils'
import { itemStepTwoSchema } from '../Schemas'
import SelectFamilyMember from '../../../SelectFamilyMember'
import BankData from './BankData'

const BeneficiaryItem = ({ formik, beneficiary, partner }) => {
  const [hasSpouse, setHasSpouse] = useState(false)

  const initialValues = {
    _id: '',
    fullName: '',
    rut: '',
    phone: '',
    email: '',
    relationship: RelationshipList3.at(0),
    birthCertificateName: [],
    birthCertificate: null,
    marriageCertificateName: [],
    marriageCertificate: null,
    isValid: false,
    toCreate: false,
    /* bank data */
    usePreviouslyLoadedData: false,
    addresseeName: '',
    addresseeRut: '',
    isRequiredAccountNumber: true,
    addresseeAccountNumber: '',
    addresseeAccountBackupName: [],
    addresseeAccountBackup: null,
    addresseeTypeAccount: AccountType.at(0),
    specificAccount: false,
    addresseeTypeAccountOther: '',
    addresseeBank: BanksList.at(0),
    specificBank: false,
    addresseeBankOther: '',
    addresseeEmail: '',
    addresseePhone: ''
  }

  const formikItem = useFormik({
    initialValues,
    validationSchema: itemStepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      formik.setValues({
        ...formik.values,
        beneficiaries: formik.values.beneficiaries.map((item) => {
          if (item.ID_CARD === beneficiary.ID_CARD) {
            return {
              ...item,
              _id: values._id,
              fullName: values.fullName,
              rut: values.rut,
              phone: values.phone,
              email: values.email,
              relationship: values.relationship,
              birthCertificateName: values.birthCertificateName,
              birthCertificate: values.birthCertificate,
              marriageCertificateName: values.marriageCertificateName,
              marriageCertificate: values.marriageCertificate,
              isValid: true,
              toCreate: values.toCreate,
              /* bank data */
              usePreviouslyLoadedData: values.usePreviouslyLoadedData,
              addresseeName: values.addresseeName,
              addresseeRut: values.addresseeRut,
              isRequiredAccountNumber: values.isRequiredAccountNumber,
              addresseeAccountNumber: values.addresseeAccountNumber,
              addresseeAccountBackupName: values.addresseeAccountBackupName,
              addresseeAccountBackup: values.addresseeAccountBackup,
              addresseeTypeAccount: values.addresseeTypeAccount,
              specificAccount: values.specificAccount,
              addresseeTypeAccountOther: values.addresseeTypeAccountOther,
              addresseeBank: values.addresseeBank,
              specificBank: values.specificBank,
              addresseeBankOther: values.addresseeBankOther,
              addresseeEmail: values.addresseeEmail,
              addresseePhone: values.addresseePhone
            }
          }
          return item
        })
      })
    }
  })

  const handleChangeRelationship = (value) => {
    formikItem.setFieldValue('relationship', value)
  }

  const handleChangeRut = () => {
    if (formikItem.values.rut) {
      const formatedRut = FormatRut(formikItem.values.rut)
      formikItem.setFieldValue('rut', formatedRut)
    }
  }

  const deleteBirthCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formikItem.values.birthCertificateName,
      formikItem.values.birthCertificate
    )

    formikItem.setValues({
      ...formikItem.values,
      birthCertificateName: resultNames,
      birthCertificate: resultListFiles
    })
  }

  const deleteMarriageCertificate = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formikItem.values.marriageCertificateName,
      formikItem.values.marriageCertificate
    )

    formikItem.setValues({
      ...formikItem.values,
      marriageCertificateName: resultNames,
      marriageCertificate: resultListFiles
    })
  }

  const handleChangeBirthCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formikItem.values.birthCertificateName,
      formikItem.values.birthCertificate
    )

    formikItem.setValues({
      ...formikItem.values,
      birthCertificateName: newListNames,
      birthCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeMarriageCertificate = (e) => {
    if (!e.currentTarget.files) {
      return
    }
    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formikItem.values.marriageCertificateName,
      formikItem.values.marriageCertificate
    )

    formikItem.setValues({
      ...formikItem.values,
      marriageCertificateName: newListNames,
      marriageCertificate: newListFiles
    })
    e.target.value = null
  }

  const handleChangeSelectFamilyMember = (dataMember) => {
    if (dataMember === 'ADD_FAMILY_MEMBER') {
      formikItem.setValues({
        ...formikItem.values,
        _id: Date.now().toString(),
        fullName: '',
        rut: '',
        phone: '',
        email: '',
        relationship: RelationshipList3.at(0),
        birthCertificateName: [],
        birthCertificate: null,
        marriageCertificateName: [],
        marriageCertificate: null,
        toCreate: true,
        isDuplicate: false,
        /* bank data */
        usePreviouslyLoadedData: false,
        addresseeName: '',
        addresseeRut: '',
        isRequiredAccountNumber: true,
        addresseeAccountNumber: '',
        addresseeAccountBackupName: [],
        addresseeAccountBackup: null,
        addresseeTypeAccount: AccountType.at(0),
        specificAccount: false,
        addresseeTypeAccountOther: '',
        addresseeBank: BanksList.at(0),
        specificBank: false,
        addresseeBankOther: '',
        addresseeEmail: '',
        addresseePhone: ''
      })
      return
    }
    formikItem.setValues({
      ...formikItem.values,
      _id: dataMember._id || '',
      fullName: dataMember.fullName || '',
      rut: dataMember.rut || '',
      phone: dataMember.phone || '',
      email: dataMember.email || '',
      relationship: dataMember.relationship || RelationshipList3.at(0),
      birthCertificateName: dataMember.birthDateCertificate || [],
      marriageCertificateName: dataMember.marriageCertificate || [],
      toCreate: false,
      isDuplicate: Boolean(
        formik.values.beneficiaries.filter(
          (item) =>
            item._id === dataMember._id && item.ID_CARD !== beneficiary.ID_CARD
        ).length > 0
      ),
      /* bank data */
      usePreviouslyLoadedData: dataMember.usePreviouslyLoadedData || false,
      addresseeName: dataMember.addresseeName || '',
      addresseeRut: dataMember.addresseeRut || '',
      isRequiredAccountNumber: dataMember.isRequiredAccountNumber || true,
      addresseeAccountNumber: dataMember.addresseeAccountNumber || '',
      addresseeAccountBackupName: dataMember.addresseeAccountBackup || [],
      addresseeTypeAccount:
        dataMember.addresseeTypeAccount || AccountType.at(0),
      specificAccount: dataMember.specificAccount || false,
      addresseeTypeAccountOther: dataMember.addresseeTypeAccountOther || '',
      addresseeBank: dataMember.addresseeBank || BanksList.at(0),
      specificBank: dataMember.specificBank || false,
      addresseeBankOther: dataMember.addresseeBankOther || '',
      addresseeEmail: dataMember.addresseeEmail || '',
      addresseePhone: dataMember.addresseePhone || ''
    })
  }

  const deleteMember = () => {
    formik.setValues({
      ...formik.values,
      beneficiaries: formik.values.beneficiaries.filter(
        (item) => item.ID_CARD !== beneficiary.ID_CARD
      )
    })
  }

  const isValidBeneficiaryItem = () => {
    const current = formik.values.beneficiaries.find(
      (item) => item.ID_CARD === beneficiary.ID_CARD
    )
    const isValid = Boolean(current.isValid)
    const isEqualsFullName = Boolean(
      current.fullName === formikItem.values.fullName
    )

    const isEqualsRut = Boolean(current.rut === formikItem.values.rut)

    const isEqualsPhone = Boolean(current.phone === formikItem.values.phone)

    const isEqualsEmail = Boolean(current.email === formikItem.values.email)

    const isEqualsRelationship = Boolean(
      current.relationship === formikItem.values.relationship
    )

    const isEqualsBirthCertificate = Boolean(
      current?.birthCertificateName?.length ===
        formikItem?.values?.birthCertificateName?.length
    )

    const isEqualsMarriageCertificate = Boolean(
      current?.marriageCertificateName?.length ===
        formikItem?.values?.marriageCertificateName?.length
    )

    if (
      isValid &&
      isEqualsFullName &&
      isEqualsRut &&
      isEqualsPhone &&
      isEqualsEmail &&
      isEqualsRelationship &&
      isEqualsBirthCertificate &&
      isEqualsMarriageCertificate
    ) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!formikItem.isValid) {
      formik.setValues({
        ...formik.values,
        beneficiaries: formik.values.beneficiaries.map((item) => {
          if (item.ID_CARD === beneficiary.ID_CARD) {
            return {
              ...item,
              _id: formikItem.values._id,
              fullName: formikItem.values.fullName,
              rut: formikItem.values.rut,
              phone: formikItem.values.phone,
              email: formikItem.values.email,
              relationship: formikItem.values.relationship,
              isValid: false,
              toCreate: formikItem.values.toCreate,
              isDuplicate: formikItem.values.isDuplicate,
              birthCertificateName: formikItem.values.birthCertificateName,
              birthCertificate: formikItem.values.birthCertificate,
              marriageCertificateName:
                formikItem.values.marriageCertificateName,
              marriageCertificate: formikItem.values.marriageCertificate,
              /* bank data */
              usePreviouslyLoadedData:
                formikItem.values.usePreviouslyLoadedData,
              addresseeName: formikItem.values.addresseeName,
              addresseeRut: formikItem.values.addresseeRut,
              isRequiredAccountNumber:
                formikItem.values.isRequiredAccountNumber,
              addresseeAccountNumber: formikItem.values.addresseeAccountNumber,
              addresseeAccountBackupName:
                formikItem.values.addresseeAccountBackupName,
              addresseeAccountBackup: formikItem.values.addresseeAccountBackup,
              addresseeTypeAccount: formikItem.values.addresseeTypeAccount,
              specificAccount: formikItem.values.specificAccount,
              addresseeTypeAccountOther:
                formikItem.values.addresseeTypeAccountOther,
              addresseeBank: formikItem.values.addresseeBank,
              specificBank: formikItem.values.specificBank,
              addresseeBankOther: formikItem.values.addresseeBankOther,
              addresseeEmail: formikItem.values.addresseeEmail,
              addresseePhone: formikItem.values.addresseePhone
            }
          }
          return item
        })
      })
    }
  }, [
    formikItem.isValid,
    beneficiary.ID_CARD,
    formik,
    formikItem.values._id,
    formikItem.values.addresseeAccountBackup,
    formikItem.values.addresseeAccountBackupName,
    formikItem.values.addresseeAccountNumber,
    formikItem.values.addresseeBank,
    formikItem.values.addresseeBankOther,
    formikItem.values.addresseeEmail,
    formikItem.values.addresseeName,
    formikItem.values.addresseePhone,
    formikItem.values.addresseeRut,
    formikItem.values.addresseeTypeAccount,
    formikItem.values.addresseeTypeAccountOther,
    formikItem.values.birthCertificate,
    formikItem.values.birthCertificateName,
    formikItem.values.email,
    formikItem.values.fullName,
    formikItem.values.isDuplicate,
    formikItem.values.isRequiredAccountNumber,
    formikItem.values.marriageCertificate,
    formikItem.values.marriageCertificateName,
    formikItem.values.phone,
    formikItem.values.relationship,
    formikItem.values.rut,
    formikItem.values.specificAccount,
    formikItem.values.specificBank,
    formikItem.values.toCreate,
    formikItem.values.usePreviouslyLoadedData
  ])

  useEffect(() => {
    formikItem.setValues({
      ...formikItem.values,
      isDuplicate: Boolean(
        formik.values.beneficiaries.filter(
          (item) =>
            item._id === formikItem.values._id &&
            item.ID_CARD !== beneficiary.ID_CARD
        ).length > 0
      )
    })
  }, [formik.values.beneficiaries, formikItem.values._id])

  useEffect(() => {
    if (beneficiary) {
      formikItem.setValues({
        _id: beneficiary._id || '',
        fullName: beneficiary.fullName || '',
        rut: beneficiary.rut || '',
        phone: beneficiary.phone || '',
        email: beneficiary.email || '',
        relationship: beneficiary.relationship || RelationshipList3.at(0),
        isValid: beneficiary.isValid || false,
        toCreate: beneficiary.toCreate || false,
        isDuplicate: beneficiary.isDuplicate || false,
        birthCertificateName: beneficiary.birthCertificateName || [],
        birthCertificate: beneficiary.birthCertificate || null,
        marriageCertificateName: beneficiary.marriageCertificateName || [],
        marriageCertificate: beneficiary.marriageCertificate || null,
        /* bank data */
        usePreviouslyLoadedData: beneficiary.usePreviouslyLoadedData || '',
        addresseeName: beneficiary.addresseeName || '',
        addresseeRut: beneficiary.addresseeRut || '',
        isRequiredAccountNumber: beneficiary.isRequiredAccountNumber || false,
        addresseeAccountNumber: beneficiary.addresseeAccountNumber || '',
        addresseeAccountBackupName:
          beneficiary.addresseeAccountBackupName || [],
        addresseeAccountBackup: beneficiary.addresseeAccountBackup || null,
        addresseeTypeAccount:
          beneficiary.addresseeTypeAccount || AccountType.at(0),
        specificAccount: beneficiary.specificAccount || false,
        addresseeTypeAccountOther: beneficiary.addresseeTypeAccountOther || '',
        addresseeBank: beneficiary.addresseeBank || BanksList.at(0),
        specificBank: beneficiary.specificBank || false,
        addresseeBankOther: beneficiary.addresseeBankOther || '',
        addresseeEmail: beneficiary.addresseeEmail || '',
        addresseePhone: beneficiary.addresseePhone || ''
      })
    }
  }, [beneficiary])

  return (
    <Grid
      templateColumns={'repeat(1, 1fr)'}
      border={'1px solid #82b378'}
      bg={isValidBeneficiaryItem() ? '#F0FFF4' : '#FFFFF0'}
      borderRadius={8}
      gap={2}
      py={1}
      px={4}
    >
      <GridItem ml={'auto'}>
        <SquareButton onClick={() => deleteMember()} size={'xs'}>
          x
        </SquareButton>
      </GridItem>
      <GridItem
        w={{
          lg: '50%',
          md: '100%',
          sm: '100%'
        }}
      >
        <InputComtrol
          label={'Seleccione un familiar ya registrado'}
          errorText={formikItem.errors.isDuplicate}
        >
          <SelectFamilyMember
            familyMemberId={formikItem.values._id}
            partnerId={partner?._id}
            setHasSpouse={setHasSpouse}
            addNewFamilyMember={formikItem.values.toCreate}
            onChange={handleChangeSelectFamilyMember}
            error={Boolean(formikItem.errors.isDuplicate)}
          />
        </InputComtrol>
      </GridItem>
      {formikItem.values._id && (
        <GridItem>
          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem>
              <InputComtrol
                id={'fullName'}
                label={'Nombres y apellidos'}
                value={formikItem.values.fullName}
                onChange={formikItem.handleChange}
                error={Boolean(formikItem.errors.fullName)}
                errorText={formikItem.errors.fullName}
              />
            </GridItem>
          </Grid>
          {/* --- */}
          <Grid
            templateColumns={{
              lg: 'repeat(2, 1fr)',
              md: 'repeat(1, 1fr)',
              sm: 'repeat(1, 1fr)'
            }}
            gap={2}
          >
            <GridItem>
              <InputComtrol id={'relationship'} label={'Parentesco'}>
                <Select
                  bg={'white'}
                  list={
                    !hasSpouse &&
                    formik.values.beneficiaries.filter(
                      (item) =>
                        item.relationship === 'Cónyuge' &&
                        item.ID_CARD !== beneficiary.ID_CARD
                    ).length === 0
                      ? RelationshipList3
                      : RelationshipList3.filter((item) => item !== 'Cónyuge')
                  }
                  value={formikItem.values.relationship}
                  onChange={handleChangeRelationship}
                />
              </InputComtrol>
              <InputComtrol
                id={`phone${beneficiary.ID_CARD}`}
                name={'phone'}
                label={'Teléfono de contacto'}
                value={formikItem.values.phone}
                onChange={formikItem.handleChange}
                addictionType={'phone'}
                error={Boolean(formikItem.errors.phone)}
                errorText={formikItem.errors.phone}
              />
            </GridItem>
            <GridItem>
              <InputComtrol
                id={`rut${beneficiary.ID_CARD}`}
                name={'rut'}
                label={'Rut'}
                value={formikItem.values.rut}
                onChange={formikItem.handleChange}
                onBlur={handleChangeRut}
                error={Boolean(formikItem.errors.rut)}
                errorText={formikItem.errors.rut}
              />
              <InputComtrol
                id={'email'}
                label={'Correo electrónico'}
                value={formikItem.values.email}
                onChange={formikItem.handleChange}
                type={'email'}
                error={Boolean(formikItem.errors.email)}
                errorText={formikItem.errors.email}
                isRequired={false}
              />
            </GridItem>
          </Grid>
          {/* --- */}
          <Grid templateColumns={'repeat(1, 1fr)'}>
            {(formikItem.values.relationship === 'Hijo' ||
              formikItem.values.relationship === 'Hija') && (
              <GridItem>
                <Text fontSize={'md'} color={'green.500'}>
                  certificado de nacimiento
                </Text>
                <UploadFile
                  id={`birthCertificate${beneficiary.ID_CARD}`}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeBirthCertificate(e)}
                  error={Boolean(formikItem.errors.birthCertificateName)}
                  errorText={formikItem.errors.birthCertificateName}
                />
                <Flex direction={'column'} gap={1}>
                  {formikItem.values.birthCertificateName?.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteBirthCertificate(item?.name)}
                    />
                  ))}
                </Flex>
              </GridItem>
            )}
            {formikItem.values.relationship === 'Cónyuge' && (
              <GridItem>
                <Text fontSize={'md'} color={'green.500'}>
                  certificado de matrimonio
                </Text>
                <UploadFile
                  id={`marriageCertificate${beneficiary.ID_CARD}`}
                  direction={'row'}
                  sizeIcon={30}
                  p={5}
                  onChange={(e) => handleChangeMarriageCertificate(e)}
                  error={Boolean(formikItem.errors.marriageCertificateName)}
                  errorText={formikItem.errors.marriageCertificateName}
                />
                <Flex direction={'column'} gap={1}>
                  {formikItem?.values?.marriageCertificateName?.map((item) => (
                    <BarFileName
                      key={item?.name}
                      fileName={item?.name}
                      fileUrl={item?.url}
                      onDelete={() => deleteMarriageCertificate(item?.name)}
                    />
                  ))}
                </Flex>
              </GridItem>
            )}
          </Grid>
          {/* --- */}
          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem>
              <BankData formik={formikItem} beneficiary={beneficiary} />
            </GridItem>
          </Grid>
          {/* --- */}

          <Grid templateColumns={'repeat(1, 1fr)'}>
            <GridItem ml={'auto'}>
              <Flex justify={'flex-end'}>
                <Tooltip
                  label={`Favor Validar! ->`}
                  placement="left"
                  bg={'#d68c49'}
                  isOpen={!isValidBeneficiaryItem()}
                >
                  <Flex>
                    <Button
                      text={
                        isValidBeneficiaryItem() ? 'Validado' : 'No validado'
                      }
                      py={1}
                      px={2}
                      onClick={formikItem.handleSubmit}
                      size={'xs'}
                    />
                  </Flex>
                </Tooltip>
              </Flex>
            </GridItem>
          </Grid>
        </GridItem>
      )}
    </Grid>
  )
}

export default BeneficiaryItem
