import React from 'react'
import { Grid, GridItem, Text, Flex, Divider } from '@chakra-ui/react'
import TrainingInformation from './TrainingInformation'
import MonitorInformation from './MonitorInformation'
import MonitorBankDataInformation from './MonitorBankDataInformation'
import ParticipantsList from './ParticipantsList'
import RenderFlowDocuments from '../RenderFlowDocuments'
import { SectionAccordion } from '../../UI'

const WorkshopsAndTraining = ({ program }) => {
  const renderViewTitle = (title, icon) => (
    <Flex
      bg={'green.100'}
      borderRadius={8}
      justify={'space-between'}
      align={'center'}
      color={'white'}
      fontSize={20}
      px={5}
      py={1}
      my={4}
      w={'-webkit-fill-available'}
    >
      <Text fontWeight={700}>{title}</Text>
      {icon}
    </Flex>
  )

  const getParticipantsCount = () => {
    let count = 0
    if (program && program.registeredPartners) {
      program?.registeredPartners.forEach((partner) => {
        if (partner.spouse) {
          count += 1
        }
      })
    }
    return count
  }

  return (
    <Grid templateColumns={'repeat(1, minmax(0, 1fr))'} gap={2}>
      <GridItem>
        {renderViewTitle('Información del taller o capacitación')}
        <TrainingInformation program={program} />
      </GridItem>
      {program?.monitorData && (
        <GridItem>
          {renderViewTitle('Información del monitor')}
          <MonitorInformation program={program} />
        </GridItem>
      )}
      {program?.bankDataMonitor && (
        <GridItem>
          {renderViewTitle('Información bancaria del monitor')}
          <MonitorBankDataInformation program={program} />
        </GridItem>
      )}

      {program?.aditionalMonitors.length > 0 &&
        renderViewTitle('Información de monitores adicionales')}
      {program?.aditionalMonitors.map((monitor, index) => (
        <GridItem
          borderWidth={1}
          borderColor="green.100"
          padding={3}
          borderRadius={6}
        >
          <GridItem>
            <MonitorInformation
              key={index}
              program={{ monitorData: monitor }}
            />
          </GridItem>
          {program?.aditionalBankData.find(
            (bankData) => bankData.monitor === monitor._id
          ) && (
            <GridItem>
              {renderViewTitle('Información bancaria del monitor')}
              <MonitorBankDataInformation
                key={index}
                program={{
                  bankDataMonitor: program?.aditionalBankData.find(
                    (bankData) => bankData.monitor === monitor._id
                  )
                }}
              />
            </GridItem>
          )}
        </GridItem>
      ))}
      <SectionAccordion
        title={`Lista de socios y cónyuges inscritos (Socios: ${
          program?.registeredPartners.length || 0
        }, Participantes: ${getParticipantsCount()})`}
      >
        <GridItem>
          <ParticipantsList program={program} />
        </GridItem>
      </SectionAccordion>
      <SectionAccordion title={'Documentos agregados dentro del flujo'}>
        <GridItem>
          <RenderFlowDocuments program={program} />
        </GridItem>
      </SectionAccordion>
    </Grid>
  )
}

export default WorkshopsAndTraining
