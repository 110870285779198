import CardStatus from '../components/UI/CardStatus'

const partner = (value) => {
  if (value === 'ACTIVE') {
    return <CardStatus subValue={'Socio'} value={'ACTIVO'} />
  }
  if (value === 'NOT-VALIDATED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        subValue={'Socio'}
        value={'NO VALIDADO'}
      />
    )
  }
  if (value === 'RESIGNED') {
    return (
      <CardStatus
        bg={'red.50'}
        color={'red.500'}
        subValue={'Socio'}
        value={'EN RENUNCIA'}
      />
    )
  }
  if (value === 'INACTIVE') {
    return (
      <CardStatus
        bg={'grey.50'}
        color={'grey.500'}
        subValue={'Socio'}
        value={'PENDIENTE DE DCTO'}
      />
    )
  }
  if (value === 'ONHOLD') {
    return (
      <CardStatus
        bg={'orange.50'}
        color={'orange.500'}
        subValue={'Socio'}
        value={'EN ESPERA'}
      />
    )
  }
  if (value === 'DECEASED') {
    return (
      <CardStatus
        bg={'red.50'}
        color={'red.500'}
        subValue={'Socio'}
        value={'FALLECIDO'}
      />
    )
  }
  return <CardStatus value={'?'} />
}

const program = (value) => {
  if (value === 'APPROVED') {
    return <CardStatus value={'◍ APROBADA'} />
  }
  if (value === 'INCOMPLETE') {
    return (
      <CardStatus bg={'grey.50'} color={'grey.500'} value={'◍ INCOMPLETO'} />
    )
  }
  if (value === 'INREVIEW') {
    return (
      <CardStatus
        bg={'orange.50'}
        color={'orange.500'}
        value={`◍ EN REVISIÓN`}
      />
    )
  }
  if (value === 'REJECTED') {
    return <CardStatus bg={'red.50'} color={'red.500'} value={'◍ RECHAZADO'} />
  }
  if (value === 'REVOKED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'◍ PIERDE BECA/OTROS'}
      />
    )
  }
  if (value === 'COMPLETED-REJECTED') {
    return (
      <CardStatus
        bg={'red.50'}
        color={'red.500'}
        value={'◍ FINALIZADA RECHAZADA'}
      />
    )
  }
  if (value === 'COMPLETED-REVOKED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'◍ FINALIZADA PIERDE BECA/OTROS'}
      />
    )
  }
  if (value === 'COMPLETED-APPROVED') {
    return (
      <CardStatus
        bg={'green.50'}
        color={'green.500'}
        value={'◍ FINALIZADA APROBADA'}
      />
    )
  }

  if (value === 'DESISTED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'◍ Desistido'}
      />
    )
  }

  if (value === 'DELETED') {
    return <CardStatus bg={'red.50'} color={'red.500'} value={'◍ ELIMINADA'} />
  }
  return <CardStatus value={'?'} />
}

const programTable = (value) => {
  if (value === 'APPROVED') {
    return <CardStatus value={'APROBADA'} />
  }
  if (value === 'INCOMPLETE') {
    return <CardStatus bg={'grey.50'} color={'grey.500'} value={'INCOMPLETO'} />
  }
  if (value === 'INREVIEW') {
    return (
      <CardStatus bg={'orange.50'} color={'orange.500'} value={`EN REVISIÓN`} />
    )
  }
  if (value === 'REJECTED') {
    return <CardStatus bg={'red.50'} color={'red.500'} value={'RECHAZADO'} />
  }
  if (value === 'REVOKED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'PIERDE BECA/OTROS'}
      />
    )
  }
  if (value === 'COMPLETED-REJECTED') {
    return (
      <CardStatus
        bg={'red.50'}
        color={'red.500'}
        value={'FINALIZADA RECHAZADA'}
      />
    )
  }
  if (value === 'COMPLETED-REVOKED') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'PIERDE BECA/OTROS'}
      />
    )
  }
  if (value === 'COMPLETED-APPROVED') {
    return (
      <CardStatus
        bg={'green.50'}
        color={'green.500'}
        value={'FINALIZADA APROBADA'}
      />
    )
  }

  if (value === 'DESISTED') {
    return (
      <CardStatus bg={'yellow.300'} color={'yellow.500'} value={'DESISTIDO'} />
    )
  }

  return <CardStatus value={'?'} />
}

const sync = (value) => {
  if (value === 'SUCCESS') {
    return <CardStatus value={'Finalizado'} />
  }
  if (value === 'IN-PROCESS') {
    return (
      <CardStatus bg={'yellow.300'} color={'yellow.500'} value={'En proceso'} />
    )
  }
  if (value === 'CREATE-REPORT') {
    return (
      <CardStatus
        bg={'yellow.300'}
        color={'yellow.500'}
        value={'Creando reporte'}
      />
    )
  }
  if (value === 'ERROR') {
    return <CardStatus bg={'red.50'} color={'red.500'} value={'Error'} />
  }
  return <CardStatus value={'?'} />
}

export default { partner, program, programTable, sync }
