import React from 'react'
import { useSelector } from 'react-redux'
import {
  Flex,
  Text,
  Spacer,
  useDisclosure,
  Tooltip,
  Box
} from '@chakra-ui/react'
import AvatarNameUser from './AvatarNameUser'
import ActionStep from './ActionStep'
import ActionStepEdit from './ActionStepEdit'
import CardNotAllowed from './CardNotAllowed'
import Roles from '../../resources/Roles'

const SHOWROLESINCARD = Boolean(process.env.REACT_APP_NODE_ENV === 'test')

const CardItemProgram = ({
  currentStep,
  state,
  index,
  currentCard,
  program,
  requestSelected,
  otherRequestSelected
}) => {
  const { user } = useSelector((state_) => state_.auth)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const getColor = () => {
    if (currentStep?.incompleteStep) {
      return { bg: 'blue.100', hover: 'blue.500', color: 'white' }
    }

    if (state === 'INREVIEW')
      return { bg: 'orange.100', hover: 'orange.500', color: 'white' }
    if (state === 'APPROVED' || state === 'COMPLETED-APPROVED')
      return { bg: 'green.100', hover: 'green.500', color: 'white' }
    if (state === 'REJECTED' || state === 'COMPLETED-REJECTED')
      return { bg: 'red.100', hover: 'red.500', color: 'white' }
    if (state === 'PENDING')
      return { bg: 'yellow.100', hover: 'yellow.500', color: 'white' }
    if (state === 'INCOMPLETE')
      return { bg: 'gray.500', hover: 'gray.600', color: 'white' }
    if (state === 'DELETED') {
      return { bg: 'red.100', hover: 'red.500', color: 'white' }
    }
    if (state === 'REVOKED') {
      return { bg: 'yellow.500', hover: 'yellow.700', color: 'white' }
    }
    if (state === 'DESISTED') {
      return { bg: 'yellow.400', hover: 'yellow.600', color: 'white' }
    }
    if (state === 'COMPLETED-REVOKED') {
      return { bg: 'yellow.500', hover: 'yellow.700', color: 'white' }
    }

    return 'gray.100'
  }

  const getOpacity = () => {
    if (currentStep?.complete || currentCard !== index) return 0.6
    return 1
  }

  const RenderRoles = ({ children, show }) => {
    if (show) {
      const tempDict = Roles.reduce((acc, current) => {
        acc[current.key] = current.name
        return acc
      }, {})
      return (
        <Tooltip
          label={currentStep?.permissions
            ?.map((item) => tempDict[item])
            .join(', ')}
          placement="bottom"
        >
          <Box>{children}</Box>
        </Tooltip>
      )
    }
    return children
  }

  return (
    <>
      {!currentStep?.permissions?.includes(user?.role) &&
        !currentStep?.complete && (
          <RenderRoles show={SHOWROLESINCARD}>
            <CardNotAllowed
              boxShadow={index === currentCard && 'dark-lg'}
              opacity={getOpacity()}
              color={getColor().color}
              currentStep={currentStep}
              cursor={
                currentStep?.complete && currentStep?.key !== 'REQUEST-ENTERED'
                  ? 'pointer'
                  : 'default'
              }
            />
          </RenderRoles>
        )}

      {currentStep?.permissions?.includes(user?.role) &&
        !currentStep?.complete && (
          <>
            <RenderRoles show={SHOWROLESINCARD}>
              <Flex
                bg={getColor().bg}
                direction={'column'}
                align={'center'}
                justify={'center'}
                borderRadius={8}
                py={8}
                px={4}
                my={3}
                mx={10}
                boxShadow={index === currentCard && 'dark-lg'}
                opacity={getOpacity()}
                color={getColor().color}
                h={'250px'}
                minW={{
                  md: '250px'
                }}
                cursor={
                  currentStep?.complete &&
                  currentStep?.key !== 'REQUEST-ENTERED'
                    ? 'pointer'
                    : 'default'
                }
                onClick={currentStep?.complete ? () => onOpen() : null}
              >
                <Text
                  fontWeight={700}
                  fontSize={{
                    lg: 'lg',
                    md: 'md',
                    sm: 'sm'
                  }}
                >
                  {currentStep?.title}
                </Text>
                <Spacer />
                {currentStep?.description && (
                  <Text fontSize={'sm'}>{currentStep?.description}</Text>
                )}
                <Spacer />
                {currentStep?.complete ? (
                  <Flex gap={2}>
                    <AvatarNameUser
                      name={
                        currentStep?.updatedBy
                          ? currentStep?.updatedBy?.name
                          : currentStep?.createdBy?.name
                      }
                      lastName={
                        currentStep?.updatedBy
                          ? currentStep?.updatedBy?.lastName
                          : currentStep?.createdBy?.lastName
                      }
                      dateNotification={
                        currentStep?.updatedAt
                          ? currentStep?.updatedAt
                          : currentStep?.date
                      }
                    />
                  </Flex>
                ) : (
                  <>
                    <ActionStep
                      currentStep={currentStep}
                      getColor={getColor}
                      program={program}
                      requestSelected={requestSelected}
                      otherRequestSelected={otherRequestSelected}
                    />
                  </>
                )}
              </Flex>
            </RenderRoles>
            {isOpen && (
              <RenderRoles show={SHOWROLESINCARD}>
                <ActionStepEdit
                  currentStep={currentStep}
                  program={program}
                  isOpen={isOpen}
                  onClose={onClose}
                  requestSelected={requestSelected}
                  otherRequestSelected={otherRequestSelected}
                />
              </RenderRoles>
            )}
          </>
        )}

      {currentStep?.complete && (
        <>
          <Flex
            bg={getColor().bg}
            direction={'column'}
            align={'center'}
            justify={'center'}
            borderRadius={8}
            py={8}
            px={4}
            my={3}
            mx={10}
            boxShadow={index === currentCard && 'dark-lg'}
            opacity={getOpacity()}
            color={getColor().color}
            h={'250px'}
            minW={{
              md: '250px'
            }}
            cursor={
              currentStep?.complete && currentStep?.key !== 'REQUEST-ENTERED'
                ? 'pointer'
                : 'default'
            }
            onClick={currentStep?.complete ? () => onOpen() : null}
          >
            <Text
              fontWeight={700}
              fontSize={{
                lg: 'lg',
                md: 'md',
                sm: 'sm'
              }}
            >
              {currentStep?.title}
            </Text>
            <Spacer />
            {currentStep?.description && (
              <Text fontSize={'sm'}>{currentStep?.description}</Text>
            )}
            <Spacer />
            {currentStep?.complete ? (
              <Flex gap={2}>
                <AvatarNameUser
                  name={
                    currentStep?.updatedBy
                      ? currentStep?.updatedBy?.name
                      : currentStep?.createdBy?.name
                  }
                  lastName={
                    currentStep?.updatedBy
                      ? currentStep?.updatedBy?.lastName
                      : currentStep?.createdBy?.lastName
                  }
                  dateNotification={
                    currentStep?.updatedAt
                      ? currentStep?.updatedAt
                      : currentStep?.date
                  }
                />
              </Flex>
            ) : (
              <>
                <ActionStep
                  currentStep={currentStep}
                  getColor={getColor}
                  program={program}
                  requestSelected={requestSelected}
                  otherRequestSelected={otherRequestSelected}
                />
              </>
            )}
          </Flex>

          {isOpen && (
            <ActionStepEdit
              currentStep={currentStep}
              program={program}
              isOpen={isOpen}
              onClose={onClose}
              requestSelected={requestSelected}
              otherRequestSelected={otherRequestSelected}
            />
          )}
        </>
      )}
    </>
  )
}

export default CardItemProgram
