import * as Yup from 'yup'
import { useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { Grid, GridItem, Flex, Text } from '@chakra-ui/react'
import {
  InputComtrol,
  Button,
  UploadFile,
  BarFileName,
  TextAreaComtrol
} from '../../../components/UI'
import { useMutateDiscounts } from '../../../hooks/Discounts'
import { GetFormData, GetNotification, Files } from '../../../Utils'
/* eslint-disable import/prefer-default-export */
export const discountSquema = Yup.object().shape({
  month: Yup.string().required('El mes es requerido'),
  year: Yup.string().required('El año es requerido'),
  amount: Yup.string().required('El monto es requerido'),
  partner: Yup.string(),
  observations: Yup.string()
})

const MONTH_DICT = {
  1: 'Enero',
  2: 'Febrero',
  3: 'Marzo',
  4: 'Abril',
  5: 'Mayo',
  6: 'Junio',
  7: 'Julio',
  8: 'Agosto',
  9: 'Septiembre',
  10: 'Octubre',
  11: 'Noviembre',
  12: 'Diciembre'
}

const DiscountFormDialog = ({ onClose, type, user = null }) => {
  const { mutate, isLoading, reset } = useMutateDiscounts(type)
  const { partnerId } = useParams()

  const formik = useFormik({
    initialValues: {
      month: '',
      year: '',
      amount: '',
      partner: partnerId,
      observations: '',
      support: [],
      supportName: []
    },
    validationSchema: discountSquema,
    onSubmit: (values) => {
      const formatValues = { ...values, month: MONTH_DICT[values.month] }

      const formData = GetFormData(formatValues, ['support', 'supportName'])

      if (type === 'CREATE') {
        mutate(formData, {
          onSuccess: () => {
            onClose()
            reset()
            formik.resetForm()
            GetNotification.basic('Descuento creado con Éxito', 'Aceptar')
          },
          onError: (err) => {
            onClose()
            console.log()
            GetNotification.basic(
              'Error',
              'Aceptar',
              `${err.response?.data?.message || 'Error al Crear descuento'}`,
              'error'
            )
          }
        })
      }
      if (type === 'UPDATE') {
        const { _id: userId } = user

        mutate(
          { userId, formData },
          {
            onSuccess: () => {
              reset()
              GetNotification.basic(
                'Descuento Actualizado con Éxito',
                'Aceptar'
              )
            },
            onError: (err) => {
              console.log(err)
              GetNotification.basic(
                'Error',
                'Aceptar',
                'Error al Actualizar descuento',
                'error'
              )
            }
          }
        )
      }
    }
  })

  const handleChangePolicePart = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      formik.values.supportName,
      formik.values.support
    )

    formik.setValues({
      ...formik.values,
      supportName: newListNames,
      support: newListFiles
    })
    e.target.value = null
  }

  const deletePolicePart = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      formik.values.supportName,
      formik.values.support
    )

    formik.setValues({
      ...formik.values,
      supportName: resultNames,
      support: resultListFiles
    })
  }

  return (
    <Grid
      templateColumns={{
        lg: 'repeat(1, 1fr)',
        md: 'repeat(1, 1fr)',
        sm: 'repeat(1, 1fr)'
      }}
      gap={2}
    >
      <GridItem w={'100%'} color={'green.500'}>
        <InputComtrol
          id={'month'}
          label={'Mes'}
          type={'number'}
          min={1}
          max={12}
          value={formik.values.month}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.month)}
          errorText={formik.errors.month}
        />
        <InputComtrol
          id={'year'}
          label={'Año'}
          type={'number'}
          value={formik.values.year}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.year)}
          errorText={formik.errors.year}
        />
        <InputComtrol
          id={'amount'}
          label={'Monto descuento'}
          type={'number'}
          value={formik.values.amount}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.amount)}
          errorText={formik.errors.amount}
        />

        <InputComtrol label={'Añadir comprobante'} isRequired={false}>
          <UploadFile
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => {
              handleChangePolicePart(e)
            }}
            id={'supportName'}
            error={Boolean(formik.errors.supportName)}
            errorText={formik.errors.supportName}
          />
          <Flex direction={'column'} gap={1}>
            {formik.values.supportName.map((item) => (
              <BarFileName
                key={item?.name}
                fileName={item?.name}
                fileUrl={item?.url}
                onDelete={() => deletePolicePart(item?.name)}
              />
            ))}
          </Flex>
        </InputComtrol>

        <TextAreaComtrol
          id={'observations'}
          isRequired={false}
          label={'Observaciones'}
          value={formik.values.observations}
          onChange={formik.handleChange}
          error={Boolean(formik.errors.observations)}
          errorText={formik.errors.observations}
        />

        <Flex justify={'flex-end'} align={'end'}>
          {type === 'CREATE' && (
            <Button
              text={'Cancelar'}
              bg={'beige.500'}
              bghover={'beige.600'}
              color={'green.500'}
              px={10}
              onClick={onClose}
            ></Button>
          )}
          <Button
            text={type === 'CREATE' ? 'Guardar' : 'Actualizar'}
            px={10}
            onClick={formik?.handleSubmit}
            isLoading={isLoading}
          ></Button>
        </Flex>
      </GridItem>
    </Grid>
  )
}

export default DiscountFormDialog
