import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import {
  TabPanels,
  TabPanel,
  Flex,
  Text,
  useToast,
  useDisclosure
} from '@chakra-ui/react'
import {
  TabList,
  Heading,
  CardStatus,
  Select,
  Spinner,
  Alert,
  Progress,
  Button,
  CustomModal
} from '../../../components/UI'
import { FormatDate } from '../../../Utils'
import GetStatusCard from '../../../Utils/GetStatusCard'
import { AddFileWhiteIcon } from '../../../components/Icons'
import FormCreatePartners from '../../../components/FormCreatePartners'
import TableFamilyPartners from '../../../components/TableFamilyPartners'
import TableDiscountsPartners from '../../../components/TableDiscountsPartners'
import TableHistoricalPartners from '../../../components/TableHistoricalPartners'
import { usePartner } from '../../../hooks/Partners'
import DiscountFormDialog from './DiscountFormDialog'

const Profile = () => {
  const toast = useToast()
  const history = useHistory()
  const { partnerId, optionDefault } = useParams()
  const { data: partner, isFetching, isLoading, error } = usePartner(partnerId)

  /* table discount */
  const initialFiltersDiscounts = {
    id: partnerId,
    order: 'DESC'
  }
  const [filtersDiscounts, setFiltersDiscounts] = useState(
    initialFiltersDiscounts
  )
  /* table discount */

  /* table family members */
  const initialFiltersFamilyMembers = {
    id: partnerId,
    order: 'DESC'
  }
  const [filtersFamilyMembers, setFiltersFamilyMembers] = useState(
    initialFiltersFamilyMembers
  )
  /* table family members */

  /* table historical programs */
  const initialFiltersPrograms = {
    id: partnerId,
    order: 'DESC'
  }
  const [filtersPrograms, setFiltersPrograms] = useState(initialFiltersPrograms)
  /* table historical programs */

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleChangeOrderDiscounts = (value) => {
    let order = 'DESC'
    if (value === 'Más Antiguo') {
      order = 'ASC'
    }
    setFiltersDiscounts({
      ...filtersDiscounts,
      order
    })
  }

  const handleChangeOrderFamilyMembers = (value) => {
    let order = 'DESC'
    if (value === 'Más Antiguo') {
      order = 'ASC'
    }
    setFiltersFamilyMembers({
      ...filtersFamilyMembers,
      order
    })
  }

  const handleChangeOrderPrograms = (value) => {
    let order = 'DESC'
    if (value === 'Más Antiguo') {
      order = 'ASC'
    }
    setFiltersPrograms({
      ...filtersPrograms,
      order
    })
  }

  const redirectToCreate = () => {
    history.push(`/programs/create/${partnerId}`)
  }

  useEffect(() => {
    toast.closeAll()
  }, [])

  return (
    <>
      {partner ? (
        <TabList
          goBack={true}
          options={[
            'Perfil de Socio',
            'Familiares',
            'Descuentos',
            'Histórico de Programas'
          ]}
          optionDefault={optionDefault}
        >
          <TabPanels>
            <TabPanel>
              <Heading title={partner?.name} sub={partner?.rut}>
                <Flex
                  gap={2}
                  mr={{
                    lg: 10
                  }}
                >
                  {error && (
                    <Flex align={'center'} ml={'auto'}>
                      <Alert
                        status={'error'}
                        message={'Error al Cargar Los Datos'}
                      />
                    </Flex>
                  )}
                  {(isLoading || isFetching) && (
                    <Flex align={'center'} ml={'auto'}>
                      <Spinner size={'lg'} />
                    </Flex>
                  )}
                  {GetStatusCard.partner(partner?.state)}
                  {partner.state !== 'NOT-VALIDATED' && (
                    <CardStatus
                      value={
                        partner?.insertDate
                          ? FormatDate(partner?.insertDate)
                          : 'Dato no proporcionado'
                      }
                      bg={partner?.insertDate ? 'green.50' : 'yellow.50'}
                      color={partner?.insertDate ? 'green.500' : 'yellow.500'}
                      subValue={'Socio desde:'}
                      px={10}
                    />
                  )}
                </Flex>
              </Heading>
              <FormCreatePartners type={'UPDATE'} partner={partner} />
            </TabPanel>
            <TabPanel>
              <Heading title={partner?.name} sub={partner?.rut} />
              <Flex w={500} gap={2} align={'center'}>
                <Text whiteSpace={'nowrap'} fontWeight={700}>
                  Ordenado por:
                </Text>
                <Select
                  list={['Más Reciente', 'Más Antiguo']}
                  onChange={handleChangeOrderFamilyMembers}
                />
              </Flex>
              <Flex py={10}>
                <TableFamilyPartners
                  filtersFamilyMembers={filtersFamilyMembers}
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Heading title={partner?.name} sub={partner?.rut} />
              <Flex w={500} gap={2} align={'center'}>
                <Text whiteSpace={'nowrap'} fontWeight={700}>
                  Ordenado por:
                </Text>
                <Select
                  list={['Más Reciente', 'Más Antiguo']}
                  onChange={handleChangeOrderDiscounts}
                />
              </Flex>
              <Flex pt={5} justify={'flex-end'}>
                <Button text={'Añadir descuentos'} onClick={onOpen} />
              </Flex>
              <Flex>
                <TableDiscountsPartners
                  filtersDiscounts={filtersDiscounts}
                  limit={6}
                />
              </Flex>
            </TabPanel>
            <TabPanel>
              <Heading title={partner?.name} sub={partner?.rut} />
              <Flex w={'100%'} gap={2} align={'center'}>
                <Flex w={500} gap={2} align={'center'}>
                  <Text whiteSpace={'nowrap'} fontWeight={700}>
                    Ordenado por:
                  </Text>
                  <Select
                    list={['Más Reciente', 'Más Antiguo']}
                    onChange={handleChangeOrderPrograms}
                  />
                </Flex>
                <Button
                  icon={AddFileWhiteIcon}
                  text={'Crear solicitud'}
                  onClick={redirectToCreate}
                />
              </Flex>
              <Flex py={10}>
                <TableHistoricalPartners filtersPrograms={filtersPrograms} />
              </Flex>
            </TabPanel>
          </TabPanels>
        </TabList>
      ) : (
        <Progress />
      )}

      {isOpen && (
        <CustomModal
          isOpen={isOpen}
          onClose={onClose}
          title={'Añadir descuentos'}
          size={'2xl'}
          showButtons={false}
        >
          <DiscountFormDialog type={'CREATE'} onClose={onClose} />
        </CustomModal>
      )}
    </>
  )
}

export default Profile
