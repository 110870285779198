import React, { useState, useEffect, useCallback } from 'react'
import {
  useDisclosure,
  Button,
  Flex,
  HStack,
  FormControl,
  FormLabel,
  Select,
  Text
} from '@chakra-ui/react'
import { CustomModal, SectionAccordion } from '../UI'
import { keyDictionary, keyDictionaryTitle } from '../../hooks/TypeModal'
import RenderDocument from './ActionStepEdit/RenderDocument'

const DocumentsResumenModal = ({ currentProgram = null }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [onlyDocumentsStep, setOnlyDocumentsStep] = useState([])
  const [requestSelected, setRequestSelected] = useState('')
  const [otherRequestSelected, setOtherRequestSelected] = useState('')

  const getInitialDocuments = useCallback(() => {
    const initialDocuments = currentProgram?.additionalInformation.filter(
      (item) =>
        item.key !== 'INFORM-THE-PAYMENT-MADE-SOLIDARITY-HEALTH-FUND' &&
        Object.values(item).some(
          (value) => Array.isArray(value) && value.length > 0
        )
    )
    return initialDocuments
  }, [currentProgram])

  useEffect(() => {
    const initialDocuments = getInitialDocuments()
    setOnlyDocumentsStep(initialDocuments)
  }, [getInitialDocuments])

  const getTypeProcess = () => {
    const additionalInformation = currentProgram?.additionalInformation.filter(
      (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
    )

    if (additionalInformation.length > 0) {
      return {
        medicines: additionalInformation[0]?.medicines,
        supplies: additionalInformation[0]?.supplies,
        tariffDifference: additionalInformation[0]?.tariffDifference,
        milkFormula: additionalInformation[0]?.milkFormula,
        others: additionalInformation[0]?.others,
        service: additionalInformation[0]?.service,
        otherService: additionalInformation[0]?.otherService,
        commission: additionalInformation[0]?.commission
      }
    }

    return {
      medicines: false,
      supplies: false,
      tariffDifference: false,
      milkFormula: false,
      others: false,
      service: null,
      otherService: null,
      commission: null
    }
  }

  const getTitleQuotesReceived = () => {
    const additionalInformation = currentProgram?.additionalInformation.filter(
      (item) => item.key === 'ADD-DEBT-CERTIFICATE'
    )
    if (additionalInformation.length > 0) {
      return 'Acta resolución de comisión'
    }

    const additionalInformation2 = currentProgram?.additionalInformation.filter(
      (item) => item.key === 'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
    )
    if (additionalInformation2.length > 0) {
      const additionalInformation3 =
        currentProgram?.additionalInformation.filter(
          (item) => item.key === 'COMMISSION-PREPARATION-SOLIDARITY-HEALTH-FUND'
        )
      if (
        additionalInformation3.length > 0 &&
        additionalInformation3[0].tariffDifference &&
        additionalInformation3[0].service !== 'Dipreca'
      ) {
        return 'Certificado de deuda'
      }
    }

    return 'Cotizaciones recibidas'
  }

  const handleRequestChange = (e) => {
    const { value } = e.target
    if (value) {
      setOtherRequestSelected('')
      const filterDocuments = currentProgram?.additionalInformation.filter(
        (item) => item.documentSelected === e.target.value
      )
      setOnlyDocumentsStep(filterDocuments)
    } else {
      const initialDocuments = getInitialDocuments()
      setOnlyDocumentsStep(initialDocuments)
    }
    setRequestSelected(e.target.value)
  }

  const handlePurchaseRequestChange = (e) => {
    const { value } = e.target

    if (value) {
      const filterDocuments = currentProgram?.additionalInformation.filter(
        (item) =>
          item.documentSelected === requestSelected ||
          item.subDocumentSelected === value
      )
      setOnlyDocumentsStep(filterDocuments)
    } else {
      const initialDocuments = getInitialDocuments()
      const filterDocuments = initialDocuments.filter(
        (item) => item.documentSelected === requestSelected
      )
      setOnlyDocumentsStep(filterDocuments)
    }
    setOtherRequestSelected(e.target.value)
  }

  const typeProcess = getTypeProcess()
  const renderRequestFilter = currentProgram?.history.history.some(
    (item) =>
      item.key ===
        'VERIFICATION-PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND' &&
      item.complete
  )

  const renderPurchaseOrderFilter = currentProgram?.history.history.some(
    (item) =>
      item.key === 'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
      item.complete
  )

  return (
    <Flex justifyContent="flex-end">
      <Button onClick={onOpen} marginBottom={4}>
        Detalle de documentación
      </Button>
      {isOpen && (
        <CustomModal
          title={`Detalle documentos - ${currentProgram?.programName}`}
          size="4xl"
          showButtons={false}
          isOpen={isOpen}
          onClose={onClose}
        >
          <HStack marginBottom={4}>
            {renderRequestFilter && (
              <FormControl>
                <FormLabel>Solicitud de ordenes de compras</FormLabel>
                <Select
                  value={requestSelected}
                  onChange={handleRequestChange}
                  placeholder="Todos"
                >
                  {currentProgram?.additionalInformation
                    .find(
                      (info) =>
                        info.key ===
                        'PURCHASE-ORDER-REQUEST-SOLIDARITY-HEALTH-FUND'
                    )
                    ?.purchaseRequestLetter.map((purchaseRequest) => (
                      <option
                        key={purchaseRequest.newName}
                        value={purchaseRequest.newName}
                      >
                        {purchaseRequest.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            )}
            {renderPurchaseOrderFilter && (
              <FormControl>
                <FormLabel>Ordenes de compras</FormLabel>
                <Select
                  value={otherRequestSelected}
                  onChange={handlePurchaseRequestChange}
                  placeholder="Todos"
                >
                  {currentProgram?.additionalInformation
                    .find(
                      (info) =>
                        info.key ===
                          'GENERATE-PURCHASE-ORDER-SOLIDARITY-HEALTH-FUND' &&
                        info.documentSelected === requestSelected
                    )
                    ?.documents.map((purchaseRequest) => (
                      <option
                        key={purchaseRequest.newName}
                        value={purchaseRequest.newName}
                      >
                        {purchaseRequest.name}
                      </option>
                    ))}
                </Select>
              </FormControl>
            )}
          </HStack>
          {onlyDocumentsStep.length > 0 ? (
            onlyDocumentsStep.map((item) => (
              <SectionAccordion
                key={item.id}
                title={`${item.stepProgram} - ${keyDictionary[item.key].title}`}
              >
                {item.commitmentLetter?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Carta de compromiso'}
                    arrayDocuments={item.commitmentLetter}
                  />
                )}
                {item.depositReceipt?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Recepción deposito'}
                    arrayDocuments={item.depositReceipt}
                  />
                )}
                {item.reportedEmail?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Correos respaldados'}
                    arrayDocuments={item.reportedEmail}
                  />
                )}
                {item.voucher?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Comprobante de depósito'}
                    arrayDocuments={item.voucher}
                  />
                )}
                {item.stockOffice?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio inventario'}
                    arrayDocuments={item.stockOffice}
                  />
                )}
                {item.deliveryProof?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Cuadro demostrativo de entrega'}
                    arrayDocuments={item.deliveryProof}
                  />
                )}
                {item.documents?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      keyDictionaryTitle[item.key]?.titleDocument ||
                      'Documentos adjuntos'
                    }
                    arrayDocuments={item.documents}
                  />
                )}
                {item.classSchedule?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Calendario de clases'}
                    arrayDocuments={item.classSchedule}
                  />
                )}
                {item.feeBill?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Boleta de honorarios'}
                    arrayDocuments={item.feeBill}
                  />
                )}
                {item.attendanceSheet?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Planilla de asistencia'}
                    arrayDocuments={item.attendanceSheet}
                  />
                )}
                {item.instructive?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Instructivo de rendición'}
                    arrayDocuments={item.instructive}
                  />
                )}
                {item.amountCancel?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento de monto a cancelar'}
                    arrayDocuments={item.amountCancel}
                  />
                )}
                {item.purchaseDetail?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento de detalle de compra'}
                    arrayDocuments={item.purchaseDetail}
                  />
                )}

                {item.backupFeeAmount?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Correo de respaldo con el monto de arancel'}
                    arrayDocuments={item.backupFeeAmount}
                  />
                )}
                {item.scholarshipCommission?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Acta de comisión de becas'}
                    arrayDocuments={item.scholarshipCommission}
                  />
                )}
                {item.bankData?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Documento con los datos bancarios'}
                    arrayDocuments={item.bankData}
                  />
                )}
                {item.office?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio'}
                    arrayDocuments={item.office}
                  />
                )}
                {item.invoice?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Factura'}
                    arrayDocuments={item.invoice}
                  />
                )}
                {item.tariffDifferenceDocuments?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Certificado de pago enviado por Dipreca'}
                    arrayDocuments={item.tariffDifferenceDocuments}
                  />
                )}
                {item.acta?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Acta de comisión'}
                    arrayDocuments={item.acta}
                  />
                )}
                {item.boxPurchase?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      typeProcess.tariffDifference &&
                      typeProcess.service === 'Dipreca'
                        ? 'Cuadro Dipreca'
                        : 'Cuadro de compra'
                    }
                    arrayDocuments={item.boxPurchase}
                  />
                )}
                {item.boxPurchaseTwo?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Cuadros de compra'}
                    arrayDocuments={item.boxPurchaseTwo}
                  />
                )}
                {item.purchaseRequestLetter?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Oficio de solicitud de orden de compra'}
                    arrayDocuments={item.purchaseRequestLetter}
                  />
                )}
                {item.quotesReceived?.length > 0 && (
                  <RenderDocument
                    titleDocument={getTitleQuotesReceived()}
                    arrayDocuments={item.quotesReceived}
                  />
                )}
                {item.debtCertificate?.length > 0 && (
                  <RenderDocument
                    titleDocument={'Certificados de deudas'}
                    arrayDocuments={item.debtCertificate}
                  />
                )}
                {item.purchaseRequestLetterSigned?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      'Oficio de solicitud de orden de compra firmada'
                    }
                    arrayDocuments={item.purchaseRequestLetterSigned}
                  />
                )}
                {item.documentsSentMedicalInstitution?.length > 0 && (
                  <RenderDocument
                    titleDocument={
                      typeProcess.tariffDifference &&
                      typeProcess.service === 'Dipreca'
                        ? 'Certificados de deuda enviado por Dipreca'
                        : 'Documentos enviados por la institución médica'
                    }
                    arrayDocuments={item.documentsSentMedicalInstitution}
                  />
                )}
              </SectionAccordion>
            ))
          ) : (
            <Text>No hay resultados</Text>
          )}
        </CustomModal>
      )}
    </Flex>
  )
}

export default DocumentsResumenModal
