import React from 'react'
import { Flex, Box, Text } from '@chakra-ui/react'
import GetStatusCard from '../../Utils/GetStatusCard'

const CardPartner = ({ partner, selectedItem, setSelectedItem }) => (
  <Flex
    w={'100%'}
    justify={'space-between'}
    gap={8}
    border={'solid 1px #2E7423'}
    borderRadius={18}
    p={10}
    _hover={{
      cursor: partner?.state !== 'DECEASED' ? 'pointer' : 'not-allowed'
    }}
    bg={selectedItem === partner?._id ? 'green.50' : 'beige.50'}
    onClick={() =>
      partner?.state !== 'DECEASED' && setSelectedItem(partner?._id)
    }
  >
    <Box>
      <Text fontWeight={700} color={'green.100'}>
        Nombre del Socio
      </Text>
      <Text fontWeight={700}>{partner?.name}</Text>
    </Box>
    <Box>
      <Text fontWeight={700} color={'green.100'}>
        Código de funcionario
      </Text>
      <Text>{partner?.code}</Text>
    </Box>
    {GetStatusCard.partner(partner?.state)}
  </Flex>
)

export default CardPartner
