import React, { useEffect } from 'react'
import { addDays } from 'date-fns'
import { useFormik } from 'formik'
import {
  Flex,
  Grid,
  GridItem,
  Text,
  useToast,
  Checkbox,
  Box,
  Tooltip
} from '@chakra-ui/react'
import { steepTwoSchema } from './Schemas'
import {
  BarFileName,
  CustomDatePicker,
  CustomToast,
  InputComtrol,
  Select,
  UploadFile,
  Button
} from '../../../UI'
import { AccountType, BanksList, Files, FormatRut } from '../../../../Utils'

const AditionalMonitor = ({
  stepTwoForm,
  setValidAditionalMonitors,
  deleteAditionalMonitor
}) => {
  const toast = useToast()
  const initialValues = {
    timestamp: Date.now(),
    fullNameMonitor: '',
    rutMonitor: '',
    birthDateMonitor: '',
    addressMonitor: '',
    phonePrimaryMonitor: '',
    phoneSecondaryMonitor: '',
    emailMonitor: '',
    certificateMonitorName: [],
    certificateMonitor: null,
    /* -bank data- */
    isRequiredAccountNumber: true,
    accountNumber: '',
    accountBackupName: [],
    accountBackup: null,
    typeAccount: AccountType.at(0),
    specificAccount: false,
    typeAccountOther: '',
    isFreeProgram: false,
    bank: BanksList.at(0),
    specificBank: false,
    bankOther: ''
  }

  const aditionalMonitorFormik = useFormik({
    initialValues,
    validationSchema: steepTwoSchema,
    validateOnMount: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setValidAditionalMonitors((prev) => {
        if (prev.length === 0) {
          return [values]
        }

        const isIncluded = prev.some(
          (item) => item.timestamp === values.timestamp
        )

        if (isIncluded) {
          return prev
        }
        return [...prev, values]
      })
    }
  })

  const handleChangeRut = () => {
    if (aditionalMonitorFormik.values.rutMonitor) {
      const formatedRut = FormatRut(aditionalMonitorFormik.values.rutMonitor)
      aditionalMonitorFormik.setFieldValue('rutMonitor', formatedRut)
    }
  }

  const handleChangeBank = (value) => {
    if (value === BanksList.at(-1)) {
      aditionalMonitorFormik.setValues({
        ...aditionalMonitorFormik.values,
        bank: value,
        specificBank: true
      })
      return
    }
    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      bank: value,
      specificBank: false
    })
  }

  const handleChangeTypeAccount = (value) => {
    if (value === 'Cuenta Rut') {
      aditionalMonitorFormik.setValues({
        ...aditionalMonitorFormik.values,
        typeAccount: value,
        isRequiredAccountNumber: false
      })
      return
    }
    if (value === AccountType.at(-1)) {
      aditionalMonitorFormik.setValues({
        ...aditionalMonitorFormik.values,
        typeAccount: value,
        specificAccount: true,
        isRequiredAccountNumber: true
      })
      return
    }
    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      typeAccount: value,
      specificAccount: false,
      isRequiredAccountNumber: true
    })
  }

  const handleChangeBirthDateMonitor = (date) => {
    aditionalMonitorFormik.setFieldValue('birthDateMonitor', date)
  }

  const deleteAccountBackup = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      aditionalMonitorFormik.values.accountBackupName,
      aditionalMonitorFormik.values.accountBackup
    )

    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      accountBackupName: resultNames,
      accountBackup: resultListFiles
    })
  }

  const deleteCertificateMonitor = (fileName) => {
    const { getArrayNamesAndArrayFiles } = Files

    const { resultNames, resultListFiles } = getArrayNamesAndArrayFiles(
      fileName,
      aditionalMonitorFormik.values.certificateMonitorName,
      aditionalMonitorFormik.values.certificateMonitor
    )

    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      certificateMonitorName: resultNames,
      certificateMonitor: resultListFiles
    })
  }

  const handleChangeCertificateMonitor = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      aditionalMonitorFormik.values.certificateMonitorName,
      aditionalMonitorFormik.values.certificateMonitor
    )

    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      certificateMonitorName: newListNames,
      certificateMonitor: newListFiles
    })
    e.target.value = null
  }

  const handleChangeAccountBackup = (e) => {
    if (!e.currentTarget.files) {
      return
    }

    const { getListNamesAndListFiles } = Files

    const { newListNames, newListFiles } = getListNamesAndListFiles(
      e.currentTarget,
      aditionalMonitorFormik.values.accountBackupName,
      aditionalMonitorFormik.values.accountBackup
    )

    aditionalMonitorFormik.setValues({
      ...aditionalMonitorFormik.values,
      accountBackupName: newListNames,
      accountBackup: newListFiles
    })
    e.target.value = null
  }

  useEffect(() => {
    // accountNumber: program?.bankDataMonitor?.accountNumber,
    // accountBackupName: program?.bankDataMonitor?.accountBackup,
    // typeAccount: program?.bankDataMonitor?.typeAccount,
    // typeAccountOther: program?.bankDataMonitor?.typeAccountOther,
    // bank: program?.bankDataMonitor?.bank,
    // bankOther: program?.bankDataMonitor?.bankOther,

    aditionalMonitorFormik.setValues({
      fullNameMonitor: stepTwoForm.fullName || '',
      rutMonitor: stepTwoForm.rut || '',
      birthDateMonitor: stepTwoForm.birthDate || '',
      addressMonitor: stepTwoForm.address || '',
      phonePrimaryMonitor: stepTwoForm.phone || '',
      phoneSecondaryMonitor: stepTwoForm.alternativePhone || '',
      emailMonitor: stepTwoForm.email || '',
      certificateMonitorName: stepTwoForm.certificate || [],
      certificateMonitor: stepTwoForm.certificateMonitor || null,
      /* -bank data- */
      isRequiredAccountNumber: Boolean(
        stepTwoForm?.typeAccount !== 'Cuenta Rut'
      ),
      isFreeProgram: stepTwoForm.isFreeProgram,
      accountNumber: stepTwoForm?.accountNumber || '',
      accountBackupName: stepTwoForm?.accountBackupName || [],
      accountBackup: stepTwoForm?.accountBackup || null,
      typeAccount: stepTwoForm?.typeAccount || AccountType.at(0),
      specificAccount: Boolean(stepTwoForm?.typeAccountOther) || false,
      typeAccountOther: stepTwoForm?.typeAccountOther || '',
      bank: stepTwoForm?.bank || BanksList.at(0),
      specificBank: Boolean(stepTwoForm?.bankOther) || false,
      bankOther: stepTwoForm?.bankOther || ''
    })
  }, [stepTwoForm])

  useEffect(() => {
    if (
      aditionalMonitorFormik.isSubmitting &&
      Object.keys(aditionalMonitorFormik.errors).length > 0
    ) {
      Object.keys(aditionalMonitorFormik.errors).forEach((key) => {
        toast({
          duration: 2000,
          isClosable: true,
          position: 'top-right',
          render: ({ onClose }) => (
            <CustomToast
              text={aditionalMonitorFormik.errors[key]}
              showSpinner={false}
              status={'error'}
              onClose={onClose}
            />
          )
        })
      })
    }
  }, [aditionalMonitorFormik.errors])

  return (
    <Box
      border={'1px solid #2e7423'}
      bg={
        aditionalMonitorFormik.dirty && aditionalMonitorFormik.isValid
          ? '#F0FFF4'
          : '#FFFFF0'
      }
      borderRadius={8}
      p={4}
      mb={4}
    >
      <Flex justifyContent="flex-end">
        <Button
          bg="red.400"
          onClick={deleteAditionalMonitor}
          text="Eliminar miembro"
        />
      </Flex>
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'fullNameMonitor'}
            label={'Nombres y apellidos'}
            value={aditionalMonitorFormik.values.fullNameMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            error={Boolean(aditionalMonitorFormik.errors.fullNameMonitor)}
            errorText={aditionalMonitorFormik.errors.fullNameMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'rutMonitor'}
            label={'RUT'}
            value={aditionalMonitorFormik.values.rutMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            onBlur={handleChangeRut}
            error={Boolean(aditionalMonitorFormik.errors.rutMonitor)}
            errorText={aditionalMonitorFormik.errors.rutMonitor}
          />
        </GridItem>
        <GridItem>
          <InputComtrol id={'birthDateMonitor'} label={'Fecha de nacimiento'}>
            <CustomDatePicker
              placeholder={'DD/MM/AAAA'}
              currentDate={aditionalMonitorFormik.values.birthDateMonitor}
              onChange={(date) => handleChangeBirthDateMonitor(date)}
              error={Boolean(aditionalMonitorFormik.errors.birthDateMonitor)}
              errorText={aditionalMonitorFormik.errors.birthDateMonitor}
              maxDate={addDays(new Date(), 0)}
            />
          </InputComtrol>
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem></GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'addressMonitor'}
            label={'Dirección'}
            value={aditionalMonitorFormik.values.addressMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            error={Boolean(aditionalMonitorFormik.errors.addressMonitor)}
            errorText={aditionalMonitorFormik.errors.addressMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem>
          <InputComtrol
            id={'phonePrimaryMonitor'}
            label={'Teléfono principal'}
            addictionType={'phone'}
            value={aditionalMonitorFormik.values.phonePrimaryMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            error={Boolean(aditionalMonitorFormik.errors.phonePrimaryMonitor)}
            errorText={aditionalMonitorFormik.errors.phonePrimaryMonitor}
          />
        </GridItem>
        <GridItem>
          <InputComtrol
            id={'phoneSecondaryMonitor'}
            isRequired={false}
            label={'Teléfono secundario'}
            addictionType={'phone'}
            value={aditionalMonitorFormik.values.phoneSecondaryMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            error={Boolean(aditionalMonitorFormik.errors.phoneSecondaryMonitor)}
            errorText={aditionalMonitorFormik.errors.phoneSecondaryMonitor}
          />
        </GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        <GridItem>
          <InputComtrol
            id={'emailMonitor'}
            label={'Correo electrónico'}
            type={'email'}
            value={aditionalMonitorFormik.values.emailMonitor}
            onChange={aditionalMonitorFormik.handleChange}
            error={Boolean(aditionalMonitorFormik.errors.emailMonitor)}
            errorText={aditionalMonitorFormik.errors.emailMonitor}
          />
        </GridItem>
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Añadir ficha del monitor
          </Text>
          <UploadFile
            id={`certificateMonitor-${stepTwoForm.timestamp}`}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeCertificateMonitor(e)}
            error={Boolean(
              aditionalMonitorFormik.errors.certificateMonitorName
            )}
            errorText={aditionalMonitorFormik.errors.certificateMonitorName}
          />
          <Flex direction={'column'} gap={1}>
            {aditionalMonitorFormik.values.certificateMonitorName.map(
              (fileName) => (
                <BarFileName
                  key={fileName?.name}
                  fileName={fileName?.name}
                  fileUrl={fileName?.url}
                  onDelete={() => deleteCertificateMonitor(fileName?.name)}
                />
              )
            )}
          </Flex>
        </GridItem>
      </Grid>
      {/* --- */}
      <Checkbox
        css={{ borderColor: 'green', marginBottom: 2 }}
        size="md"
        colorScheme="green"
        id={'isFreeProgram'}
        isChecked={aditionalMonitorFormik.values.isFreeProgram}
        onChange={aditionalMonitorFormik.handleChange}
      >
        ¿Servicio gratuito?
      </Checkbox>
      {!aditionalMonitorFormik.values.isFreeProgram && (
        <Grid
          templateColumns={{
            lg: 'repeat(2, 1fr)',
            md: 'repeat(1, 1fr)',
            sm: 'repeat(1, 1fr)'
          }}
          gap={2}
        >
          <GridItem>
            <Text fontWeight={700} color={'green.500'} fontSize={'xl'}>
              Información bancaria
            </Text>
            <InputComtrol id={'bank'} label={'Banco del destinatario'}>
              <Select
                bg={'white'}
                list={BanksList}
                value={aditionalMonitorFormik.values.bank}
                onChange={handleChangeBank}
              />
            </InputComtrol>
            {aditionalMonitorFormik.values.specificBank && (
              <InputComtrol
                id={'bankOther'}
                label={'Especificar banco'}
                type={'text'}
                value={aditionalMonitorFormik.values.bankOther}
                onChange={aditionalMonitorFormik.handleChange}
                error={Boolean(aditionalMonitorFormik.errors.bankOther)}
                errorText={aditionalMonitorFormik.errors.bankOther}
              />
            )}
          </GridItem>
          {/* - */}
          <GridItem>
            <Text fontSize={'xl'} opacity={0}>
              -----
            </Text>
            <InputComtrol id={'typeAccount'} label={'Tipo de Cuenta'}>
              <Select
                bg={'white'}
                list={AccountType}
                value={aditionalMonitorFormik.values.typeAccount}
                onChange={(value) => handleChangeTypeAccount(value)}
              />
            </InputComtrol>
            {aditionalMonitorFormik.values.specificAccount && (
              <InputComtrol
                id={'typeAccountOther'}
                label={'Especificar tipo de cuenta'}
                type={'text'}
                value={aditionalMonitorFormik.values.typeAccountOther}
                onChange={aditionalMonitorFormik.handleChange}
                error={Boolean(aditionalMonitorFormik.errors.typeAccount)}
                errorText={aditionalMonitorFormik.errors.typeAccount}
              />
            )}
          </GridItem>
        </Grid>
      )}
      {/* --- */}
      <Grid
        templateColumns={{
          lg: 'repeat(2, 1fr)',
          md: 'repeat(1, 1fr)',
          sm: 'repeat(1, 1fr)'
        }}
        gap={2}
      >
        <GridItem></GridItem>
      </Grid>
      {/* --- */}
      <Grid templateColumns={'repeat(1, 1fr)'} gap={2}>
        {!aditionalMonitorFormik.values.isFreeProgram &&
          aditionalMonitorFormik.values.isRequiredAccountNumber && (
            <GridItem>
              <InputComtrol
                id={'accountNumber'}
                label={'Nº de cuenta'}
                value={aditionalMonitorFormik.values.accountNumber}
                onChange={aditionalMonitorFormik.handleChange}
                error={Boolean(aditionalMonitorFormik.errors.accountNumber)}
                errorText={aditionalMonitorFormik.errors.accountNumber}
              />
            </GridItem>
          )}
        <GridItem>
          <Text fontSize={'md'} color={'green.500'}>
            Respaldo de cuenta del monitor (opcional)
          </Text>
          <UploadFile
            id={`accountBackup-${stepTwoForm.timestamp}`}
            direction={'row'}
            sizeIcon={30}
            p={5}
            onChange={(e) => handleChangeAccountBackup(e)}
            error={Boolean(aditionalMonitorFormik.errors.accountBackupName)}
            errorText={aditionalMonitorFormik.errors.accountBackupName}
          />
          <Flex direction={'column'} gap={1}>
            {aditionalMonitorFormik.values.accountBackupName.map((fileName) => (
              <BarFileName
                key={fileName?.name}
                fileName={fileName?.name}
                fileUrl={fileName?.url}
                onDelete={() => deleteAccountBackup(fileName?.name)}
              />
            ))}
          </Flex>
        </GridItem>
        <Flex justify={'flex-end'}>
          <Tooltip
            label="Favor Validar! ->"
            placement="left"
            bg="#d68c49"
            isOpen
          >
            <Flex>
              <Button
                text={
                  aditionalMonitorFormik.dirty && aditionalMonitorFormik.isValid
                    ? 'Validado'
                    : 'Validar'
                }
                py={1}
                px={2}
                onClick={aditionalMonitorFormik.handleSubmit}
                size={'xs'}
              />
            </Flex>
          </Tooltip>
        </Flex>
      </Grid>
    </Box>
  )
}

export default AditionalMonitor
