/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation, useQueryClient } from 'react-query'

import {
  getDiscountsByPartner,
  createNewDiscount,
  updateDiscount,
  deleteDiscount
} from '../api/Discounts'

const key = 'discounts'

const getAction = (type) => {
  if (type === 'CREATE') return createNewDiscount
  if (type === 'UPDATE') return updateDiscount
  if (type === 'DELETE') return deleteDiscount
  return null
}

export function useDiscounts(filtersDiscounts, page, limit, props = {}) {
  return useQuery(
    [key, filtersDiscounts, page, limit],
    () => getDiscountsByPartner(filtersDiscounts, page, limit),
    {
      ...props
    }
  )
}

export function useMutateDiscounts(type) {
  const queryClient = useQueryClient()
  const actionFn = getAction(type)
  return useMutation(actionFn, {
    onSuccess: () => {
      queryClient.invalidateQueries([key])
    }
  })
}
