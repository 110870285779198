const getStatus = {
  INREVIEW: 'En revisión',
  APPROVED: 'Aprobado',
  REJECTED: 'Rechazado',
  'COMPLETED-REJECTED': 'Finalizada Rechazada',
  'COMPLETED-APPROVED': 'Finalizada Aprobada',
  CANCELED: 'Cancelado',
  INCOMPLETE: 'Incompleto',
  REVOKED: 'Pierde Beca/Otros',
  'COMPLETED-REVOKED': 'Pierde Beca/Otros'
}

const getStatusColor = {
  INREVIEW: '#f29407',
  APPROVED: '#07b007',
  REJECTED: '#fc0505',
  'COMPLETED-REJECTED': '#fc0505',
  'COMPLETED-APPROVED': '#07b007',
  CANCELED: '#fc0505',
  INCOMPLETE: '#4287f5',
  REVOKED: '#a99a40',
  'COMPLETED-REVOKED': '#a99a40'
}

const getStatusColorBg = {
  INREVIEW: '#f2c580',
  APPROVED: '#82f582',
  REJECTED: '#ed6b6b',
  'COMPLETED-REJECTED': '#ed6b6b',
  'COMPLETED-APPROVED': '#82f582',
  CANCELED: '#ed6b6b',
  INCOMPLETE: '#a9c6f5',
  REVOKED: '#f6e05e',
  'COMPLETED-REVOKED': '#f6e05e'
}

const list = [
  { key: '', name: 'TODOS' },
  { key: 'INREVIEW', name: 'En revisión' },
  { key: 'APPROVED', name: 'Aprobado' },
  { key: 'REJECTED', name: 'Rechazado' },
  { key: 'COMPLETED-REJECTED', name: 'Finalizada rechazada' },
  { key: 'COMPLETED-APPROVED', name: 'Finalizada aprobada' },
  { key: 'INCOMPLETE', name: 'Incompleto' },
  /* { key: 'REVOKED', name: 'Pierde Beca/Otros' }, */
  { key: 'COMPLETED-REVOKED', name: 'Pierde Beca/Otros' }
]

const partner = [
  { key: '', name: 'TODOS' },
  { key: 'ACTIVE', name: 'ACTIVO' },
  { key: 'INACTIVE', name: 'PENDIENTE DE DCTO' },
  { key: 'ONHOLD', name: 'EN ESPERA' },
  { key: 'NOT-VALIDATED', name: 'NO VALIDADO' },
  { key: 'RESIGNED', name: 'EN RENUNCIA' },
  { key: 'DECEASED', name: 'FALLECIDO' }
  /* { key: 'DELETED', name: 'ELIMINADO' } */
]

export default { getStatus, list, getStatusColor, getStatusColorBg, partner }
